<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");
export default {
  name: "App",
  data() {
    return {
      zh_CN,
    };
  },
};
</script>

<style lang="scss">
html,
body,
div,
ul,
b,
li,
i,
em,
h1,
h2,
h3,
span,
a {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

html,
body {
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}

.searchCondition {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .searchConditionItem {
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
    align-items: center;
    span {
      white-space: nowrap;
    }
    .ant-input-affix-wrapper {
      width: 240px;

      .ant-input {
        width: 100%;
      }
    }
    .ant-input {
      width: 240px;
    }

    .ant-select {
      width: 240px;
    }
    .ant-calendar-picker-input {
      width: 100% !important;
    }
  }

  .searchButton {
    button {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}
.reminder {
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
  color: black;
}
.ant-btn-link {
  padding: 0 !important;
  font-size: 12px !important;
  span {
    font-weight: 800;
  }
  &[disabled] {
    color: #00000040 !important;
  }
}
.ant-btn.ant-btn-loading {
  padding-left: 20px !important;
}
.ant-btn::before {
  background-color: transparent !important;
}
a {
  font-size: 12px;
  &[disabled] {
    color: #00000040 !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
}
.ant-table-body {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ededed;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #7c7c7c;
  }
}
.ant-pagination {
  .ant-pagination-item {
    min-width: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
    a {
      padding: 0 8px !important;
      font-size: 14px !important;
    }
  }
}
.ant-popover-message-title {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table {
  border: none !important;
  font-size: 12px !important;
  font-weight: 500;
  color: #000 !important;
  table {
    border: 1px solid #e8e8e8 !important;
    .ant-table-thead > tr > th {
      font-weight: 500;
      background: #fafafa !important;
      border-bottom: 1px solid #509ee3 !important;
    }
    .ant-table-tbody {
      td {
        border: none !important;
      }
    }
  }
  .ant-table-fixed-right {
    border-left: none !important;
  }
}
.resize-table-th {
  position: relative;
  .table-draggable-handle {
    transform: none !important;
    position: absolute;
    height: 100% !important;
    bottom: 0;
    left: auto !important;
    right: -5px;
    cursor: col-resize;
    touch-action: none;
  }
}

.slotCell {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .content {
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.dark-row {
  background: #e6f7ff42;
}
.light-row {
  background: #ffffff;
}
</style>
