<template>
  <div class="ftpFileListByDir">
    <div class="searchCondition">
      <div class="searchConditionItem" style="height: 32px">
        路径：<template v-for="(item, index) in pathArr">
          <template v-if="index !== pathArr.length - 1">
            <a-button
              type="link"
              @click="skipDirectory(item)"
              style="font-size: 14px !important"
            >
              {{ item }}
              /
            </a-button>
          </template>
          <template v-else> {{ item }}/ </template>
        </template>
      </div>
    </div>
    <a-table
      size="small"
      class="fileList"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      :loading="loadingTable"
      :rowKey="(record) => record.path"
      :scroll="{ y: '600px' }"
    >
      <span slot="fileName" slot-scope="text, record">
        <a-button
          type="link"
          style="padding: 0"
          @click="clickName(record)"
          v-if="record.dir"
        >
          {{ text }}
        </a-button>
        <template v-else>{{ text }}</template>
      </span>
      <span slot="dir" slot-scope="text, record">
        <template v-if="record.dir"> 文件夹 </template>
        <template v-else> 文件 </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="download(record)" v-if="!record.dir">
          下载
        </a>
      </span>
    </a-table>
  </div>
</template>
<script>
export default {
  name: "ftpFileListByDir",
  props: ["ftpId", "initialPath"],
  data() {
    return {
      loadingTable: false,
      tableColumns: [
        {
          title: "名称",
          dataIndex: "fileName",
          scopedSlots: { customRender: "fileName" },
        },
        {
          title: "文件大小",
          dataIndex: "readableFileSize",
          width: 120,
        },
        {
          title: "类型",
          dataIndex: "dir",
          scopedSlots: { customRender: "dir" },
          width: 80,
        },
        {
          title: "最后更新时间",
          dataIndex: "lastModifyAt",
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 60,
        },
      ],
      tableDataSource: [],
      path: "/",
    };
  },
  computed: {
    pathArr() {
      let arr = this.path.split("/").filter((item) => {
        return item !== "";
      });
      arr.unshift("root");
      return arr;
    },
  },
  mounted() {
    this.path = this.initialPath;
    this.query();
  },
  methods: {
    skipDirectory(item) {
      if (item == "root") {
        this.path = "/";
      } else {
        this.path = this.path.split(item + "/")[0] + item + "/";
      }
      this.query();
    },
    query() {
      this.loadingTable = true;
      let data = {
        ftpId: this.ftpId,
        path: this.path,
      };
      this.$http.get("/ftp/fileListByDir", data).then((res) => {
        if (res.result === 200) {
          this.tableDataSource = res.data;
          this.tableDataSource.forEach((item) => {
            item.path =
              this.path +
              (this.path == this.initialPath || this.path == "/" ? "" : "/") +
              item.fileName;
          });
          this.loadingTable = false;
        }
      });
    },
    clickName(record) {
      if (record.dir) {
        this.path = record.path;
        this.query();
      }
    },
    download(record) {
      location.href =
        (process.env.VUE_APP_CURRENTMODE == "dev"
          ? process.env.VUE_APP_BASE_API
          : location.origin + "/api") +
        `/ftp/downloadFile?ftpId=${this.ftpId}&fullPath=${record.path}`;
    },
  },
};
</script>
<style lang="scss">
.ftpFileListByDir {
  .ant-table-header {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
