<template>
  <div class="timedTask">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>任务名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入任务名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>状态：</span>
        <a-select
          v-model="statusSelect"
          allowClear
          placeholder="请选择"
          @change="query(1)"
        >
          <a-select-option
            v-for="(item, index) in statusList"
            :key="index"
            :value="item.code"
          >
            {{ item.desc }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <span>关联分组：</span>
        <a-select
          v-model="taskGroupId"
          placeholder="请选择"
          showSearch
          :filterOption="false"
          allowClear
          @search="searchGroupList"
          @blur="getGroupList()"
          @change="query(1)"
        >
          <a-select-option
            v-for="item in groupList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <span>提交人：</span>
        <a-select
          v-model="timedSubmitter"
          placeholder="请选择"
          showSearch
          :filterOption="false"
          allowClear
          @search="searchUserList"
          @blur="getUserList()"
          @change="query(1)"
          style="width: 100px"
        >
          <a-select-option
            v-for="item in submitterList"
            :key="item.id"
            :value="item.loginName"
          >
            {{ item.userName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="newTimedTask" icon="plus"
          >创建定时任务</a-button
        >
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="status" slot-scope="text, record">
        <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
      </span>
      <span slot="cron" slot-scope="text, record">
        {{ cronFormat(text) }}
        <a-icon
          type="edit"
          @click="update_cron(record)"
          style="color: #1890ff"
        />
      </span>
      <span slot="groupName" slot-scope="text, record">
        {{ text }}
        <a-icon
          type="edit"
          @click="update_group(record)"
          style="color: #1890ff"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="left"
          okText="确认"
          cancelText="取消"
          @confirm="up_pipeline(record)"
          v-if="record.status == 'STOP'"
        >
          <template slot="title"
            >是否确认启动【{{ record.name }}】定时</template
          >
          <a href="javascript:;">启动定时</a>
        </a-popconfirm>
        <a-popconfirm
          placement="left"
          okText="确认"
          cancelText="取消"
          @confirm="stop_pipeline(record)"
          v-else
        >
          <template slot="title"
            >是否确认停止【{{ record.name }}】定时</template
          >
          <a href="javascript:;">停止定时</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-button type="link" class="text-button" @click="subtaskList(record)"
          >子任务列表</a-button
        >
        <a-divider type="vertical" />
        <a-button
          type="link"
          class="text-button"
          @click="update_pipeline(record)"
          >修改</a-button
        >
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button
                type="link"
                size="small"
                @click="copySchedulePipelineTask(record)"
                >复制任务</a-button
              >
            </a-menu-item>
            <a-menu-item>
              <a-button
                type="link"
                size="small"
                @click="setPipelineTaskClean(record)"
                >设置清理周期</a-button
              >
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="executeTimedTask(record)"
              >
                <template slot="title"
                  >确认是否立即执行{{ record.name }}</template
                >
                <a href="javascript:;" style="color: #1890ff">立即执行</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="delete_pipeline(record)"
              >
                <template slot="title">确认是否删除{{ record.name }}</template>
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <a-modal
      :maskClosable="false"
      :title="title"
      v-model="createShow"
      width="420px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称">
          <a-input
            v-model="taskName"
            placeholder="请输入名称"
            style="width: 90%"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="create_submit()">确 定</a-button>
          <a-button @click="createShow = false">取 消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="修改cron表达式"
      v-model="updateCronShow"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="cron表达式:">
          <a-select
            v-model="cronSelect"
            placeholder="请选择cron表达式"
            style="width: 160px"
          >
            <a-select-option
              v-for="item in cronList"
              :key="item.id"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
          <a-input
            v-if="cronSelect == ''"
            v-model="cron"
            placeholder="请输入cron表达式"
            style="width: 160px; margin-left: 5px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="updateCronSubmit()">确 定</a-button>
          <a-button @click="updateCronShow = false">取 消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="子任务列表"
      v-if="subtaskListShow"
      v-model="subtaskListShow"
      width="1200px"
      :footer="null"
    >
      <subtask-list
        parentType="SCHEDULED_TASK_TEMPLATE"
        pipelineTaskType="SCHEDULED_TASK"
        :parentTaskId="parentTaskId"
      ></subtask-list>
    </a-modal>

    <a-modal
      width="500px"
      title="设置清理周期"
      v-model="setPipelineTaskCleanShow"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="清理周期:">
          <a-select v-model="day" placeholder="请选择清理周期">
            <a-select-option
              v-for="(item, index) in dayList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="setPipelineTaskCleanShow = false">取 消</a-button>
          <a-button type="primary" @click="setPipelineTaskCleanSubmit"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      width="500px"
      title="修改绑定分组"
      v-model="updateGroupShow"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="关联分组:">
          <a-select
            v-model="updateGroupId"
            placeholder="请选择"
            showSearch
            :filterOption="false"
            @search="searchGroupList"
            @blur="getGroupList()"
          >
            <a-select-option
              v-for="item in updateGroupList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="updateGroupShow = false">取消</a-button>
          <a-button type="primary" @click="updateGroupSubmit">确定</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import subtaskList from "./subtaskList.vue";
export default {
  name: "timedTask",
  components: { subtaskList },
  data() {
    return {
      name: "",
      statusSelect: undefined,
      timedSubmitter: undefined,
      submitterList: [],
      taskId: "",
      title: "",
      cronTaskShow: false,
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          width: 250,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "cron表达式",
          ellipsis: true,
          dataIndex: "cron",
          width: 200,
          scopedSlots: { customRender: "cron" },
        },
        {
          title: "关联分组",
          ellipsis: true,
          dataIndex: "groupName",
          width: 200,
          scopedSlots: { customRender: "groupName" },
        },
        {
          title: "下次执行时间",
          ellipsis: true,
          dataIndex: "nextExecuteTime",
          align: "center",
          width: 170,
        },
        {
          title: "创建人",
          ellipsis: true,
          dataIndex: "userName",
          width: 100,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          align: "center",
          width: 120,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 340,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      currentPage: 1,
      createShow: false,
      taskName: "",
      parentTaskId: 0,
      subtaskListShow: false,
      updateCronShow: false,
      cronSelect: "",
      cron: "",
      updateId: "",
      id: "",
      cronList: [
        { id: 1, label: "每分钟同步一次", value: "0 * * * * ?" },
        { id: 2, label: "五分钟同步一次", value: "0 */5 * * * ?" },
        { id: 3, label: "十分钟同步一次", value: "0 */10 * * * ?" },
        { id: 4, label: "十五分钟同步一次", value: "0 */15 * * * ?" },
        { id: 5, label: "三十分钟同步一次", value: "0 */30 * * * ?" },
        { id: 6, label: "一小时同步一次", value: "0 0 * * * ?" },
        { id: 7, label: "自定义", value: "" },
      ],
      statusList: [
        {
          code: "STOP",
          desc: "已停止",
        },
        {
          code: "WAITING",
          desc: "等待中",
        },
        {
          code: "",
          desc: "全部",
        },
      ],
      day: undefined,
      setPipelineTaskCleanShow: false,
      dayList: [
        { label: "一天", value: 1 },
        { label: "两天", value: 2 },
        { label: "三天", value: 3 },
        { label: "七天", value: 7 },
        { label: "十五天", value: 15 },
      ],
      searchTimer: null,
      taskGroupId: undefined,
      updateGroupId: -1,
      updateGroupShow: false,
      groupList: [],
      updateGroupList: [],
    };
  },
  computed: {
    cronFormat() {
      return (cron) => {
        let arr = this.cronList.filter((item) => {
          return item.value == cron && cron;
        });
        if (arr.length > 0) {
          return arr[0].label;
        }
        return cron;
      };
    },
  },
  watch: {
    timedSubmitter: {
      handler(newVal) {
        localStorage.setItem("timedSubmitter", newVal);
      },
    },
  },
  created() {
    if (
      localStorage.getItem("timedSubmitter") !== "undefined" &&
      localStorage.getItem("timedSubmitter")
    ) {
      this.timedSubmitter = localStorage.getItem("timedSubmitter");
    }
    this.getGroupList();
    this.query(1);
  },
  methods: {
    searchGroupList(name) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.getGroupList(name);
      }, 300);
    },
    getGroupList(name) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        type: "SCHEDULED_TASK_GROUP",
        name,
      };
      this.$http
        .get("/commonOptionInfo/commonOptionInfoPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.groupList.splice(0);
            this.updateGroupList.splice(0);
            this.groupList.push(...res.data.records);
            this.updateGroupList.push(...res.data.records);
            this.updateGroupList.unshift({ id: -1, name: "无" });
          }
        });
    },
    searchUserList(name) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.getUserList(name);
      }, 300);
    },
    getUserList(name) {
      this.$http.get("/user/list", { name }).then((res) => {
        if (res.result === 200) {
          this.submitterList = res.data;
        }
      });
    },
    // 子任务列表
    subtaskList(val) {
      this.parentTaskId = val.id;
      this.subtaskListShow = true;
    },
    create_submit() {
      if (this.title == "创建定时任务") {
        let data = {
          name: this.taskName,
          model: "BATCH",
          pipelineTaskType: "SCHEDULED_TASK_TEMPLATE",
        };
        this.$http
          .json_post("/pipelineTask/initBlankTask", data)
          .then((res) => {
            if (res.result == 200) {
              this.createShow = false;
              this.$router.push({
                path: "/panel",
                query: {
                  id: res.data.pipelineTask.id,
                },
              });
            }
          });
      } else {
        let data = {
          name: this.taskName,
          id: this.taskId,
        };
        this.$http
          .json_post("/sysPipeline/copySchedulePipelineTask", data)
          .then((res) => {
            if (res.result == 200) {
              this.query(1);
              this.createShow = false;
            }
          });
      }
    },
    generateTaskName() {
      this.$http.get("/sysPipeline/generateName").then((res) => {
        if (res.result === 200) {
          this.taskName = res.data.name;
          this.createShow = true;
        }
      });
    },
    newTimedTask() {
      this.title = "创建定时任务";
      this.generateTaskName();
    },
    // 点击重新创建
    recreateClick(val) {
      this.query(1);
    },
    update_cron(val) {
      this.updateId = val.id;
      let select = this.cronList.some((item) => {
        return item.value == val.cron;
      });
      if (select) {
        this.cronSelect = val.cron;
        this.cron = "";
      } else {
        this.cronSelect = "";
        this.cron = val.cron;
      }
      this.updateCronShow = true;
    },
    updateCronSubmit() {
      let data = {
        id: this.updateId,
        cron: this.cronSelect,
      };
      if (this.cronSelect == "") {
        data.cron = this.cron.trim();
      }
      this.$http.json_post("/pipelineTask/updateTaskCron", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.updateCronShow = false;
          this.query();
        }
      });
    },
    update_group(val) {
      this.updateId = val.id;
      this.updateGroupId = val.taskGroupId;
      this.updateGroupShow = true;
    },
    updateGroupSubmit() {
      let data = {
        taskId: this.updateId,
        groupId: this.updateGroupId,
      };
      this.$http
        .json_post(
          "/scheduleTaskGroupRelation/bindScheduleTaskGroupRelation",
          data
        )
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.updateGroupShow = false;
            this.query();
          }
        });
    },
    // 修改定时任务
    update_pipeline(val) {
      this.$router.push({
        path: "/panel",
        query: {
          id: val.id,
        },
      });
    },
    // 停止定时任务
    stop_pipeline(val) {
      let data = {
        id: val.id,
        pipelineTaskStatus: "STOP",
      };
      this.$http
        .json_post("/pipelineTask/modifyPipelineCronTask", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("停止成功");
            this.query();
          }
        });
    },
    // 启动定时任务
    up_pipeline(val) {
      let data = {
        id: val.id,
        pipelineTaskStatus: "WAITING",
      };
      this.$http
        .json_post("/pipelineTask/modifyPipelineCronTask", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("启动成功");
            this.query();
          }
        });
    },
    copySchedulePipelineTask(val) {
      this.title = "复制定时任务";
      this.taskId = val.id;
      this.generateTaskName();
    },
    setPipelineTaskClean(val) {
      this.taskId = val.id;
      let data = {
        taskId: this.taskId,
      };
      this.$http.get("/pipelineTask/pipelineTaskClean", data).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.day = res.data.day;
            this.id = res.data.id;
          } else {
            this.day = undefined;
            this.id = undefined;
          }
          this.setPipelineTaskCleanShow = true;
        }
      });
    },
    setPipelineTaskCleanSubmit() {
      let data = {
        taskId: this.taskId,
        day: this.day,
        id: this.id,
      };
      this.$http
        .json_post("/pipelineTask/modifyPipelineTaskClean", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.setPipelineTaskCleanShow = false;
          }
        });
    },
    executeTimedTask(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/pipelineTask/executeTimedTask", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("执行成功");
            this.query();
          }
        });
    },
    // 删除任务
    delete_pipeline(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/delete", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        name: this.name,
        status: this.statusSelect,
        pipelineTaskType: "SCHEDULED_TASK_TEMPLATE",
        submitter: this.timedSubmitter,
        taskGroupId: this.taskGroupId,
      };
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            switch (item.status) {
              case "RUNNING":
                item.statusColor = "green";
                item.statusLabel = "运行中";
                break;
              case "STOP":
                item.statusColor = "red";
                item.statusLabel = "已停止";
                break;
              case "WAITING":
                item.statusColor = "orange";
                item.statusLabel = "等待中";
                break;
            }
            if (!item.taskGroupId || item.taskGroupId == -1) {
              item.groupName = "无";
            }
          });
          this.tableData = list;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
