<template>
  <div class="processList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>我的收藏：</span>
        <a-checkbox v-model="myCollect" @change="query(1)"></a-checkbox>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addPipeline()">
          创建自定义节点
        </a-button>
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="icon" slot-scope="text, record">
        <img :src="record.icon" alt="" style="width: 25px; height: 25px" />
      </span>
      <span slot="starterNode" slot-scope="text, record">
        <a-tag v-if="text" color="green"> 是 </a-tag>
        <a-tag v-if="!text" color="red"> 否 </a-tag>
      </span>
      <span slot="minInputCount" slot-scope="text, record">
        [{{ record.minInputCount }}:{{ record.maxInputCount }}]
      </span>
      <span slot="minOutputCount" slot-scope="text, record">
        [{{ record.minOutputCount }}:{{ record.maxOutputCount }}]
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="modify_pipeline(record)"
          >修改流程</a-button
        >
        <a-divider type="vertical" />
        <a-button type="link" @click="update_node(record)">修改信息</a-button>
        <a-divider type="vertical" />
        <a-button type="link" @click="copyDiyNode(record)">复制</a-button>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button type="link" size="small" @click="collect(record)">{{
                record.collect ? "取消收藏" : "收藏"
              }}</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="delete_pipeline(record)"
              >
                <template slot="title">确认是否删除{{ record.name }}</template>
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <a-modal
      :maskClosable="false"
      title="创建自定义节点"
      v-model="createShow"
      width="420px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称">
          <a-input
            v-model="taskName"
            placeholder="请输入名称"
            style="width: 80%"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-select
            v-model="pipelineProcessNodeGroup"
            placeholder="请选择类型"
            style="width: 80%"
          >
            <a-select-option
              v-for="item in processGroupList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.group }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="create_submit()">确 定</a-button>
          <a-button @click="createShow = false">取 消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="修改节点"
      v-model="dialogVisible"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="节点名称:">
          <a-input
            v-model="nameAdd"
            placeholder="请输入节点名称"
            style="width: 80%"
          />
        </a-form-item>
        <a-form-item label="输入文件类型:">
          <a-select
            v-model="inFileType"
            placeholder="请选择"
            mode="multiple"
            allowClear
          >
            <a-select-option
              v-for="item in filetypeList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="输出文件类型:">
          <a-select
            v-model="outFileType"
            placeholder="请选择"
            mode="multiple"
            allowClear
          >
            <a-select-option
              v-for="item in filetypeList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="适用类型:">
          <a-input
            v-model="availableType"
            placeholder="请输入适用类型"
            style="width: 80%"
          />
        </a-form-item>
        <a-form-item label="搜索关键词:">
          <a-textarea
            v-model="tag"
            placeholder="请输入搜索关键词"
            style="width: 80%"
            :rows="5"
          />
        </a-form-item>
        <a-form-item label="图标:">
          <a-select
            v-model="icon"
            placeholder="请选择图标"
            showSearch
            :filterOption="false"
            @search="queryImageList"
            style="width: 201px"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            dropdownClassName="iconSelect"
          >
            <a-select-option
              v-for="(item, index) in imageList"
              :key="index"
              :value="item.imageUrl"
            >
              <img :src="item.imageUrl" style="width: 20px; height: 20px" />
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注:">
          <a-textarea
            :rows="4"
            placeholder="请输入备注"
            style="width: 80%"
            v-model="remark"
          />
        </a-form-item>
        <a-form-item label="需要审核:">
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="needAudit"
          />
          可测试:
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="testable"
          />
        </a-form-item>
        <a-form-item label="动态计算百分比:">
          <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="dynamicUpdateProgress"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="update_submit">确定</a-button>
          <a-button @click="dialogVisible = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="复制自定义节点"
      v-model="copyDiyNodeShow"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称">
          <a-input v-model="diyNodeName" placeholder="请输入名称" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="copyDiyNodeSubmit()">确 定</a-button>
          <a-button @click="copyDiyNodeShow = false">取 消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "processList",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 60,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "group",
          width: 120,
        },
        {
          title: "节点名称",
          ellipsis: true,
          dataIndex: "name",
          width: 250,
        },
        {
          title: "图标",
          ellipsis: true,
          dataIndex: "icon",
          scopedSlots: { customRender: "icon" },
          align: "center",
          width: 50,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 250,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      currentPage: 1,
      name: "",
      createShow: false,
      taskName: "",
      myCollect: false,
      processGroupList: [],
      pipelineProcessNodeGroup: undefined,
      id: "",
      dialogVisible: false,
      nameAdd: "",
      typeAdd: "",
      needAudit: undefined,
      dynamicUpdateProgress: undefined,
      remark: "",
      testable: undefined,
      availableType: "",
      imageList: [],
      tag: "",
      icon: "",
      groupList: [],
      groupAdd: undefined,
      filetypeList: [
        { id: 1, value: "excel" },
        { id: 2, value: "csv" },
        { id: 3, value: "text" },
        { id: 4, value: "zip" },
      ],
      inFileType: [],
      outFileType: [],
      iconShow: true,
      watchIcon: undefined,
      nodeInfoId: "",
      diyNodeName: "",
      copyDiyNodeShow: false,
    };
  },
  watch: {
    myCollect: {
      handler(newVal) {
        localStorage.setItem("pipelineListMyCollect", newVal);
      },
    },
  },
  mounted() {
    if (
      localStorage.getItem("pipelineListMyCollect") &&
      localStorage.getItem("pipelineListMyCollect") == "true"
    ) {
      this.myCollect = true;
    } else {
      this.myCollect = false;
    }
    this.query(1);
    this.queryImageList();
    this.getGroupList();
    this.getProcessGroupList();
  },
  methods: {
    focusQueryImageList() {
      this.$nextTick(() => {
        this.iconShow = true;
        if (!this.icon) {
          this.icon = this.watchIcon;
        } else {
          this.watchIcon = this.icon;
        }
      });
      this.queryImageList();
    },
    queryImageList(key) {
      this.imageList.splice(0);
      this.$http
        .get("/pipelineNodeInfo/queryImageList", { key })
        .then((res) => {
          if (res.result === 200) {
            this.imageList.push(...res.data);
          }
        });
    },
    getGroupList() {
      this.$http.get("/pipelineNodeInfo/groupList").then((res) => {
        if (res.result === 200) {
          this.groupList = res.data;
        }
      });
    },
    iconChange() {
      this.icon = undefined;
      this.iconShow = false;
      this.$nextTick(() => {
        this.$refs.iconSelect.focus();
      });
    },
    getProcessGroupList() {
      this.$http.get("/pipelineNodeInfo/processGroupList").then((res) => {
        if (res.result === 200) {
          this.processGroupList = res.data;
        }
      });
    },
    create_submit() {
      let data = {
        name: this.taskName,
        pipelineProcessNodeGroup: this.pipelineProcessNodeGroup,
        model: "BATCH",
        pipelineTaskType: "PROCESS",
      };
      this.$http.json_post("/pipelineTask/initBlankTask", data).then((res) => {
        this.$router.push({
          path: "/panel",
          query: {
            id: res.data.pipelineTask.id,
          },
        });
      });
    },
    // 点击新增
    addPipeline() {
      this.taskName = "";
      this.pipelineProcessNodeGroup = this.processGroupList[0].value;
      this.createShow = true;
    },
    collect(val) {
      let data = {
        sourceId: val.id,
        collect: !val.collect,
        type: "PROCESS",
      };
      this.$http
        .json_post("/pipelineTask/collectTaskResource", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("操作成功");
            this.query();
          }
        });
    },
    // 修改
    modify_pipeline(val) {
      this.$router.push({
        path: "/panel",
        query: {
          id: val.sourceId,
        },
      });
    },
    update_submit() {
      let data = {
        name: this.nameAdd,
        type: this.typeAdd,
        group: this.groupAdd,
        inFileType: this.inFileType.join(","),
        outFileType: this.outFileType.join(","),
        needAudit: this.needAudit,
        dynamicUpdateProgress: this.dynamicUpdateProgress,
        remark: this.remark,
        testable: this.testable,
        availableType: this.availableType,
        icon: this.icon,
        tag: this.tag,
        id: this.id,
      };
      this.$http
        .json_post("/pipelineNodeInfo/editPipelineNodeInfo", data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.dialogVisible = false;
            this.query();
          }
        });
    },
    // 修改
    update_node(val) {
      this.title = "修改";
      this.iconShow = true;
      this.dialogVisible = true;
      this.nameAdd = val.name;
      this.id = val.id;
      this.typeAdd = val.type;
      this.groupAdd = val.group;
      if (val.inFileType) {
        this.inFileType = val.inFileType.split(",");
      } else {
        this.inFileType = [];
      }
      if (val.outFileType) {
        this.outFileType = val.outFileType.split(",");
      } else {
        this.outFileType = [];
      }
      this.needAudit = val.needAudit;
      this.dynamicUpdateProgress = val.dynamicUpdateProgress;
      this.remark = val.remark;
      this.testable = val.testable;
      this.availableType = val.availableType;
      this.icon = val.icon;
      this.tag = val.tag;
      this.watchIcon = val.icon;
    },
    copyDiyNode(val) {
      this.nodeInfoId = val.id;
      this.diyNodeName = "";
      this.copyDiyNodeShow = true;
    },
    copyDiyNodeSubmit() {
      let data = {
        nodeInfoId: this.nodeInfoId,
        diyNodeName: this.diyNodeName,
      };
      this.$http.json_post("/pipelineTask/copyDiyNode", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("复制成功");
          this.copyDiyNodeShow = false;
          this.$router.push({
            path: "/panel",
            query: {
              id: res.data,
            },
          });
        }
      });
    },
    // 删除
    delete_pipeline(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineNodeInfo/deleteNode", data).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        name: this.name,
        myCollect: this.myCollect,
        queryProcessNode: true,
      };
      this.$http
        .get("/pipelineNodeInfo/pipelineNodeInfoPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.tableData = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_data(current),
            };
            this.loadingTable = false;
          }
        });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
